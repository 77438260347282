import { useEffect, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { useLocalStorageState } from '../../hooks/useLocalStorageState'
import { useDeviceProps } from "../../Providers/DeviceProps"
import './style.scss'


export const FullScreenPage = ({ disabled }) => {
  const [audio] = useState(() => {
    const _audio = new Audio('/sounds/windy.wav')
    _audio.volume = 0
    return _audio
  })
  const [wasPlayed, setWasPlayed] = useState(false)
  const [play, setPlay] = useState(false)
  const handle = useFullScreenHandle()
  const [mobileDetected] =  useLocalStorageState('__mobileDetected', useDeviceProps().mobileDetected)
  const { wasResized } = useWindowDimensions({
    w: 0,
    h: 150
  })

  const probablyInspectating = (!mobileDetected || mobileDetected === 'false') && wasResized

  useEffect(() => {
    if (!wasPlayed && probablyInspectating) {
      audio.volume = 1
      audio.play().then(() => setWasPlayed(true)).catch(() => setWasPlayed(false))
    }
  }, [probablyInspectating, play, wasPlayed, audio])

  if (disabled) return null

  return <div onClick={() => setPlay(true)} onMouseDown={() => setPlay(true)} onTouchStart={() => setPlay(true)}>
    {
      probablyInspectating &&
        <FullScreen handle={handle} className="fullscreen-content">
          <p className="fullscreen-text">You found something...</p>
          <div className="fullscreen-blink-bg" />
          <div className="fullscreen-images-wrapper">
            <img className="fullscreen-image" src="/gifs/ghost.gif" alt="" />
            {/* <img className="fullscreen-image" src="/gifs/portal.gif" alt="" /> */}
          </div>
          <div className="fullscreen-button">
            <button onClick={() => window.location.reload()}>Go back</button>
          </div>
        </FullScreen>
    }
  </div>
}
