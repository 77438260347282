import { useEffect } from 'react'

export const useDisableDragImageEffect = () => {
  useEffect(() => {
    const prevent = e => e.preventDefault()

    document.addEventListener('dragstart', prevent)
    return () => {
      document.removeEventListener('dragstart', prevent)
    }
  }, [])
}
