import { useLayoutEffect, useState } from 'react'

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function(e) {
      reject(src)
    }
    img.src = src
  })
}

export const useImagePreloader = (images) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false)

  useLayoutEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      const imagesPromiseList = []
      for (const file of images) {
        imagesPromiseList.push(preloadImage(file))
      }
  
      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [images])

  return [ imagesPreloaded ]
}
