import React, { Suspense, useEffect, useRef } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useDeviceProps } from '../../Providers/DeviceProps'
import { useModeProps } from '../../Providers/ModeProps'
import { useSkillOpenedPermission } from '../../Providers/SkillOpenedProps'

import './style.scss'


const loadContent = () => import('../Content')
const Content = React.lazy(loadContent)

export const Skills = () => {
  const ref = useRef()
  const { isMobile, isTablet } = useDeviceProps()
  const { mode, scrollbarClasses } = useModeProps()
  const { opened, closing, canLoad, refs, addRef, closeIfOpened } = useSkillOpenedPermission()
  useEffect(() => {
    if (!refs.includes(ref)) addRef(ref)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (canLoad) {
    loadContent()
  }

  return <>
    <div ref={ref} className={`flex flex-col gap-y-6 w-fit items-center py-2 transition-all duration-700 absolute rounded-full ${opened ? 'top-[50vh]' : 'top-[-550vh]'} left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
      <div className="absolute cursor-pointer left-2 top-2 text-xl transform text-white opacity-60 hover:opacity-100 bg-[] backdrop:blur-sm" onClick={closeIfOpened}><FaArrowLeft /></div>
      <div className={`skills-content overflow-x-scroll py-1 md:px-10 md:py-3 ${ scrollbarClasses } ${isMobile || isTablet ? 'w-screen' : 'w-[100vw] xl:w-[50vw]'}`}>
        <Suspense fallback={<div className="w-full text-center">...</div>}>
          {opened || closing ? <Content /> : null}
        </Suspense>
      </div>
      <div className={`${mode} z-[-1] py-2 w-full h-full top-1/2 left-1/2 blured-menu absolute transform -translate-x-1/2 -translate-y-1/2 rounded-2xl`}></div>
    </div>
  </>

}
