import { useState, useEffect, useMemo } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export const useWindowDimensions = (threshold = {
  w: 0,
  h: 0
}) => {
  const [initialWindowDimensions] = useState(() => getWindowDimensions())
  const [resizeCounter, setResizeCounter] = useState(() => 0)
  const [windowDimensions, setWindowDimensions] = useState(initialWindowDimensions)
  const wasWidthResized = !(Math.abs(initialWindowDimensions.width - windowDimensions.width) <= threshold.w)
  const wasHeightResized = !(Math.abs(initialWindowDimensions.height - windowDimensions.height) <= threshold.h)
  const wasResized = wasWidthResized || wasHeightResized
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialProportion = useMemo(() => initialWindowDimensions.width / initialWindowDimensions.height, [])
  const currentProportion = useMemo(() => windowDimensions.width / windowDimensions.height, [windowDimensions.height, windowDimensions.width])

  useEffect(() => {
    setResizeCounter(p => p + 1)
  }, [wasResized])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...windowDimensions,
    wasResized,
    wasWidthResized,
    wasHeightResized,
    initialProportion,
    currentProportion,
    resizeCounter
  }
}
