import { useEffect } from 'react'
import Loading from 'react-fullscreen-loading'

import { useTimeout } from '../hooks/useTimeout'
import { useImagePreloader } from '../hooks/useImagePreloader'
import { getAllImagesList } from '../functions/images'
import { useModeProps } from '../Providers/ModeProps'

import { config } from '../config'


const { MIN_LOADING } = config

const __loading_color__ = {
  'night' : {
    bg: '#132e4f',
    loader: '#38b9fa'
  },
  'day': {
    bg: '#38b9fa',
    loader: '#132e4f'
  }
}

const __allImagesList__ = getAllImagesList()

export const Preload = ({
  preloadImages = __allImagesList__,
  loadingEffect = false,
  delay = MIN_LOADING,
  onLoad = () => {} }) => {
  const { mode, switchMode } = useModeProps()

  const [hasDelayed] = useTimeout(delay)
  const [hasLoaded] = useImagePreloader(preloadImages)

  const loading = !hasDelayed || !hasLoaded

  // day/light switch after loading
  useEffect(() => {
    switchMode()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loadingEffect || !loading) {
      onLoad()
      switchMode()
    }
    // eslint-disable-next-line
  }, [loading, hasDelayed])

  if (loadingEffect)
    return <Loading
      loading={loading}
      background={__loading_color__[ mode ].bg}
      loaderColor={__loading_color__[ mode ].loader}
    />

  return <> </>
}
