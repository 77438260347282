const data = {
  'day-1': {
    id: '58375f56-f8dd-4144-933c-63f4b2a13a00',
    dimensions: {
      clock: {w: '432', h: '96'}, // 432 × 96 px
      high: {w: '720', h: '160'}, // 720 × 160 px
      medium: {w: '720', h: '160'}, // 720 × 160 px
      mobile: {w: '683', h: '151'}, // 683 × 151 px
      public: {w: '720', h: '160'}, // 720 × 160 px
    }
  },
  'day-2': {
    id: '44e16136-17da-47c2-aa48-53ab4dc64a00',
    dimensions: {
      clock: {w: '432', h: '68'}, // 432 × 68 px
      high: {w: '1260', h: '200'}, // 1260 × 200 px
      medium: {w: '996', h: '158'}, // 996 × 158 px
      mobile: {w: '683', h: '108'}, // 	683 × 108 px
      public: {w: '1260', h: '200'}, // 1260 × 200 px
    }
  },
  'day-3-v3': {
    id: '3ea0c680-1c7d-4bbd-a510-4f11a25e7300',
    dimensions: {
      clock: {w: '362', h: '148'}, // 362 × 148 px
      high: {w: '2000', h: '817'}, // 2000 × 817 px
      medium: {w: '996', h: '406'}, // 996 × 406 px
      mobile: {w: '683', h: '279'}, // 683 × 279 px
      public: {w: '1366', h: '558'}, // 1366 × 558 px
    }
  },
  'day-4-v2': {
    id: 'd74792a1-098b-4160-2a56-763b6a8b8300',
    dimensions: {
      clock: {w: '370', h: '148'}, // 370 × 148 px
      high: {w: '2000', h: '800'}, // 2000 × 800 px
      medium: {w: '996', h: '398'}, // 996 × 398 px
      mobile: {w: '683', h: '273'}, // 	683 × 273 px
      public: {w: '1366', h: '546'}, // 1366 × 546 px
    }
  },
  'day-5-v2': {
    id: 'c509b09f-347a-410c-3249-34acbf991800',
    dimensions: {
      clock: {w: '282', h: '148'}, // 282 × 148 px
      high: {w: '2000', h: '1046'}, // 2000 × 1046 px
      medium: {w: '996', h: '520'}, // 996 × 520 px
      mobile: {w: '683', h: '357'}, // 683 × 357 px
      public: {w: '1366', h: '714'}, // 1366 × 714 px
    }
  },
  'day-6.2': {
    id: 'd21b7f68-6726-4e3e-44a7-677e48e49400',
    dimensions: {
      clock: {w: '363', h: '148'}, // 363 × 148 px
      high: {w: '432', h: '176'}, // 432 × 176 px
      medium: {w: '432', h: '176'}, // 432 × 176 px
      mobile: {w: '432', h: '176'}, // 432 × 176 px
      public: {w: '432', h: '176'}, // 432 × 176 px
    }
  },
  'day-6.5.1': {
    id: '77addc47-6d7c-4fb4-29ec-7428c193f900',
    dimensions: {
      clock: {w: '173', h: '148'}, // 173 × 148 px
      high: {w: '681', h: '582'}, // 681 × 582 px
      medium: {w: '655', h: '560'}, // 655 × 560 px
      mobile: {w: '449', h: '384'}, // 449 × 384 px
      public: {w: '681', h: '582'}, // 681 × 582 px
    }
  },
  'day-7-v2': {
    id: 'a8144418-ec5a-4948-7651-b07e73bf2800',
    dimensions: {
      clock: {w: '230', h: '148'}, // 230 × 148 px
      high: {w: '1230', h: '790'}, // 1230 × 790 px
      medium: {w: '871', h: '560'}, // 871 × 560 px
      mobile: {w: '597', h: '384'}, // 597 × 384 px
      public: {w: '1195', h: '768'}, // 1195 × 768 px
    }
  },
  'day-8-v2': {
    id: '64456104-863e-4fa0-bd3a-c2c21e1cc600',
    dimensions: {
      clock: {w: '128', h: '148'}, // 128 × 148 px
      high: {w: '2000', h: '2300'}, // 2000 × 2300 px
      medium: {w: '486', h: '560'}, // 486 × 560 px
      mobile: {w: '333', h: '384'}, // 333 × 384 px
      public: {w: '667', h: '768'}, // 667 × 768 px
    }
  },
  'day-9': {
    id: 'd078e06c-717f-44fb-fd1b-fcce085cf200',
    dimensions: {
      clock: {w: '152', h: '148'}, // 152 × 148 px
      high: {w: '340', h: '330'}, // 340 × 330 px
      medium: {w: '340', h: '330'}, // 340 × 330 px
      mobile: {w: '340', h: '330'}, // 340 × 330 px
      public: {w: '340', h: '330'}, // 340 × 330 px
    }
  },
  'night-1': {
    id: '22cb5753-e738-4d87-0e9f-3d0342114700',
    dimensions: {
      clock: {w: '432', h: '96'}, // 432 × 96 px
      high: {w: '720', h: '160'}, // 720 × 160 px
      medium: {w: '720', h: '160'}, // 720 × 160 px
      mobile: {w: '683', h: '151'}, // 683 × 151 px
      public: {w: '720', h: '160'}, // 720 × 160 px
    }
  },
  'night-2': {
    id: 'e9d15e74-9695-4bac-c522-e82e5f2e2200',
    dimensions: {
      clock: {w: '432', h: '68'}, // 432 × 68 px
      high: {w: '1260', h: '200'}, // 1260 × 200 px
      medium: {w: '996', h: '158'}, // 996 × 158 px
      mobile: {w: '683', h: '108'}, // 	683 × 108 px
      public: {w: '1260', h: '200'}, // 1260 × 200 px
    }
  },
  'night-3-v3': {
    id: '7fd68ace-db65-401b-6722-da9687df7800',
    dimensions: {
      clock: {w: '362', h: '148'}, // 362 × 148 px
      high: {w: '2000', h: '817'}, // 2000 × 817 px
      medium: {w: '996', h: '406'}, // 996 × 406 px
      mobile: {w: '683', h: '279'}, // 683 × 279 px
      public: {w: '1366', h: '558'}, // 1366 × 558 px
    }
  },
  'night-4-v2': {
    id: 'dd37e796-dd7c-4803-3776-6031f5d59d00',
    dimensions: {
      clock: {w: '370', h: '148'}, // 370 × 148 px
      high: {w: '2000', h: '800'}, // 2000 × 800 px
      medium: {w: '996', h: '398'}, // 996 × 398 px
      mobile: {w: '683', h: '273'}, // 	683 × 273 px
      public: {w: '1366', h: '546'}, // 1366 × 546 px
    }
  },
  'night-5-v2': {
    id: '4d6ceea5-53aa-45b3-3d4f-e645d7b91b00',
    dimensions: {
      clock: {w: '282', h: '148'}, // 282 × 148 px
      high: {w: '2000', h: '1046'}, // 2000 × 1046 px
      medium: {w: '996', h: '520'}, // 996 × 520 px
      mobile: {w: '683', h: '357'}, // 683 × 357 px
      public: {w: '1366', h: '714'}, // 1366 × 714 px
    }
  },
  'night-6.2': {
    id: '3822fafe-3cbe-4742-5656-12cdb8694300',
    dimensions: {
      lock: {w: '363', h: '148'}, // 363 × 148 px
      high: {w: '432', h: '176'}, // 432 × 176 px
      medium: {w: '432', h: '176'}, // 432 × 176 px
      mobile: {w: '432', h: '176'}, // 432 × 176 px
      public: {w: '432', h: '176'}, // 432 × 176 px
    }
  },
  'night-6.5.1': {
    id: '4e320c25-649f-4f5d-0556-aa62e7e2e100',
    dimensions: {
      clock: {w: '173', h: '148'}, // 173 × 148 px
      high: {w: '681', h: '582'}, // 681 × 582 px
      medium: {w: '655', h: '560'}, // 655 × 560 px
      mobile: {w: '449', h: '384'}, // 449 × 384 px
      public: {w: '681', h: '582'}, // 681 × 582 px
    }
  },
  'night-7-v2': {
    id: '764559da-45c7-4097-56fe-bc9ee6fcd900',
    dimensions: {
      clock: {w: '230', h: '148'}, // 230 × 148 px
      high: {w: '1230', h: '790'}, // 1230 × 790 px
      medium: {w: '871', h: '560'}, // 871 × 560 px
      mobile: {w: '597', h: '384'}, // 597 × 384 px
      public: {w: '1195', h: '768'}, // 1195 × 768 px
    }
  },
  'night-8-v2': {
    id: 'dcda90e8-077e-4049-a9d2-7300469b8400',
    dimensions: {
      clock: {w: '128', h: '148'}, // 128 × 148 px
      high: {w: '2000', h: '2300'}, // 2000 × 2300 px
      medium: {w: '486', h: '560'}, // 486 × 560 px
      mobile: {w: '333', h: '384'}, // 333 × 384 px
      public: {w: '667', h: '768'}, // 667 × 768 px
    }
  },
  'night-9': {
    id: '22bc596a-1a41-4f7a-c6af-017b2d301900',
    dimensions: {
      clock: {w: '152', h: '148'}, // 152 × 148 px
      high: {w: '340', h: '330'}, // 340 × 330 px
      medium: {w: '340', h: '330'}, // 340 × 330 px
      mobile: {w: '340', h: '330'}, // 340 × 330 px
      public: {w: '340', h: '330'}, // 340 × 330 px
    }
  },
  'chest_1': {
    local: true,
    path: '/gifs/',
    type: 'gif'
  },
  'chest_2': {
    local: true,
    path: '/gifs/',
    type: 'gif'
  },
  'chest_3': {
    local: true,
    path: '/gifs/',
    type: 'gif'
  },
  'chest_4': {
    local: true,
    path: '/gifs/',
    type: 'gif'
  },
  'ghost': {
    local: true,
    path: '/gifs/',
    type: 'gif'
  },
  'still': {
    local: true,
    path: '/gifs/',
    type: 'png'
  },
  'still_2': {
    local: true,
    path: '/gifs/',
    type: 'png'
  },
}

export default data
