import React, { memo } from 'react'
import { useSkillOpenedPermission } from '../../Providers/SkillOpenedProps'
import { useModeProps } from '../../Providers/ModeProps'
import { useDebounce } from '../../hooks/useDebounce'
import './style.scss'


const srcByPosition = {
  'closed': '/gifs/chest_1.gif',
  'opening': '/gifs/chest_3.gif',
  'opened': '/gifs/still_2.png',
  'closing': '/gifs/chest_4.gif',
}

export const ChestCurriculum = memo(({ className = ''}) => {
  const { mode } = useModeProps()
  const {
    opened,
    status,
    canClick,
    toggleOpened
  } = useSkillOpenedPermission()

  const src = srcByPosition[useDebounce(status, 120)]
  return <div
    className={`${className} ${mode} ${status} chest-wrapper z-50 transition-all duration-1000`}
    onClick={canClick ? toggleOpened : () => {}}
    style={{
      opacity: opened ? '0.3' : '1'
    }}>
    <div className="chest">
      <img src={src} alt={`chest ${status}`} />
    </div>
  </div>
})
