import { useState, useEffect }from 'react'

const { stringify, parse } = JSON

export const useLocalStorageState = (_field, _default) => {
  const type = typeof _default

  const getLocalValue = () =>  {
    // No need to update local storage here
    //  since useEffect will run on first render
    const itemValue = window.localStorage.getItem(_field)

    if (type === 'object') {
      return parse(itemValue)
    }

    return itemValue ?? _default
  }

  const [field, setField] = useState(getLocalValue)

  useEffect(()=>{
    // Runs on first render and
    //  everytime field value changes
    const serializedField = type === 'object' ? stringify(field) : field
    window.localStorage.setItem(_field, serializedField)

    // eslint-disable-next-line
  }, [field])

  return [field, setField]
}
