import { useRef, useState, useCallback } from 'react'
import { createContext, useContext } from 'react'
import { useSpring } from 'react-spring'

import { useLocalStorageState } from '../hooks/useLocalStorageState'

import config from '../config'


const pureColors = {
  day: '#3f3f3f',
  night: '#ffffff'
}

const { SPRING_CONFIG } = config

export const ModePropsContext = createContext()

export const ModePropsProvider = ({ children }) => {
  const parallax = useRef()
  const [mode, setMode] = useLocalStorageState('mode', 'day')
  const [focus, setFocus] = useState(0)

  const onSetFocus = useCallback(([, value]) => {
    setFocus(value)
  }, [])

  const isDay = mode === 'day'
  const { opacity, color, invertedColor } = useSpring({
    reverse: isDay,
    from: {
      opacity: 0,
      color: pureColors.day,
      invertedColor: pureColors.night
    },
    to: {
        opacity: 1,
        color: pureColors.night,
        invertedColor: pureColors.day
    },
    config: {
      precision: 0,
      ...SPRING_CONFIG
    }
  })

  const __switch__ = {
    'day' : 'night',
    'night': 'day'
  }

  const switchMode = () => {
    setMode(__switch__[mode])
  }

  const scrollbarClasses = `scrollbar-thin ${mode === 'day' ? 'scrollbar-thumb-sky-d' : 'scrollbar-thumb-sky-n'} scrollbar-track`

  return <ModePropsContext.Provider value={{
    parallax,
    mode,
    setMode,
    switchMode,
    opacity,
    color,
    invertedColor,
    pureColor: pureColors[mode],
    scrollbarClasses,
    onSetFocus,
    focus,
    busy: parallax?.current?.busy
  }} >
      {children}
    </ModePropsContext.Provider>
}

export const useModeProps = () => {
  const context = useContext(ModePropsContext)
  if (!context) {
    throw new Error('useModeProps must be used within a ModePropsProvider')
  }

  return context
}
