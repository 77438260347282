export const config = {
  PAGES: 2,
  MIN_LOADING: 0.8,
  MAX_MOBILE: 480,
  MAX_TABLET: 1024,
  MAX_LAPTOPS: 1280,
  SPRING_CONFIG: {
    mass: 1,
    tension: 200,
    friction: 35
  },
  SCREEN_THRESHOLD: 60
}

export default config
