import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

export const ModeSwitch = ({
  style,
  className,
  onClick = () => {} }) => {

  const [on, seton] = useState(false)

  const toogleOn = (e) => {
    seton(previous => !previous)
    onClick(e)
  }

  const properties = {
    on: {
      r: 9,
      transform: "rotate(40deg)",
      cx: 12,
      cy: 4,
      opacity: 0
    },
    off: {
      r: 5,
      transform: "rotate(90deg)",
      cx: 30,
      cy: 0,
      opacity: 1
    }
  }

  const { r, transform, cx, cy, opacity } = properties[
    on ? 'on' : 'off'
  ]

  const svgContainerProps = useSpring({ transform })
  const centerCircleProps = useSpring({ r })
  const maskedCircleProps = useSpring({ cx, cy })
  const linesProps = useSpring({ opacity })

  const Svg = <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={toogleOn}
      className={className}
      style={{
        ...style,
        cursor: "pointer",
        ...svgContainerProps,
        color: 'white'
      }}
    >
      <mask id="mask">
        <rect x="0" y="0" width="100%" height="100%" fill="white" /> 
        <animated.circle style={maskedCircleProps} r="9" fill="black" />
      </mask>
      <animated.circle
        cx="12"
        cy="12"
        fill="white"
        style={centerCircleProps}
        mask="url(#mask)"
      />
      <animated.g stroke="currentColor" style={linesProps}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </animated.g>
    </animated.svg>

  return Svg
}
