import React, { useRef, useState, useEffect, Children, useCallback, useMemo } from 'react'
import get from 'lodash/get'

// import { useModeProps } from '../../Providers/ModeProps'
// import { useScrollPercentage } from '../../hooks/useScrollPercentage'
import { useDeviceProps } from '../../Providers/DeviceProps'
import { useContainerDimensions } from '../../hooks/useContainerDimensions'
import { getImage } from '../../functions/images'
import './style.scss'

// const blurInitialValues = {
//   "flower": -1.5,
//   "tree-land": 0,
//   "main-land": 2.5,
//   "main-sky": 3.4,
//   "front-sky": 3,
//   "cloud-2": 2.5,
//   "cloud-5": 2,
//   "moon": 8,
//   "planet": 8,
// }

export const SubtractedImg = ({
  name,
  style,
  alt,
  dinPositionMult = 1,
  fixedPositionMult = 1,
  children,
  className,
  useChildren = false,
  ...props }) => {

  // const [blurValue, setBlurValue] = useState(blurInitialValues[alt])
  // const { parallax } = useModeProps()
  // const { perc } = useScrollPercentage(parallax)

  const ref = useRef(null)
  const { 
    windowHeight,
    windowWidth,
    // isMobile
  } = useDeviceProps()

  const {height: dimensionHeight} = useContainerDimensions(ref)
  const [height, setHeight] = useState(() => dimensionHeight)

  const offsetHeight = get(ref, 'current.offsetHeight', 0)

  useEffect(() => {
    if (height !== offsetHeight && style.opacity === 1)
      setHeight(() => get(ref, 'current.offsetHeight', 0))
    // eslint-disable-next-line
  }, [offsetHeight])

  const handleOnLoad = () => {
    setHeight(get(ref, 'current.offsetHeight', 0))
  }

  const marginNumber =
    (height * dinPositionMult / 100 * 86) + (windowHeight * 0.15 * fixedPositionMult)
  const marginTop = `-${ marginNumber }px`

  const { url, w, h } = useMemo(() => getImage(name, windowWidth), [name, windowWidth])

  // useEffect(() => {
  //   if (!isMobile) {
  //     const newPerc = (100 - perc) > 20 ? (100 - perc) : 0
  //     const factor = (newPerc * blurInitialValues.moon) / 100
  //     setBlurValue(blurInitialValues[alt] - factor)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [perc, isMobile])

  const renderChildren = useCallback(() => useChildren && Children.map(children, (child, index) => {
    if(!React.isValidElement(child)) {
      return null
    }
    return React.cloneElement(child,
      {
        style: {
          ...child.props.style,
          ...style,
          marginTop,
          // ...(!isMobile
          //   ? {
          //       filter: blurValue ? `blur(${Math.abs(blurValue)}px)` : '',
          //       transition: 'filter .5s ease-in-out'
          //     }
          //   : {})
        }
      })
  // }), [blurValue, children, isMobile, marginTop, style, useChildren])
  }), [children, marginTop, style, useChildren])

  if (!useChildren) {
    return <img
      {...props}

      className={className}
      ref={ref}
      alt={alt}
      src={url}
      width={w}
      height={h}
      style={{
        ...style,
        marginTop,
        // ...(!isMobile
        //   ? {
        //       filter: blurValue ? `blur(${Math.abs(blurValue)}px)` : '',
        //       transition: 'filter .5s ease-in-out'
        //     }
        //   : {})
      }}
      onLoad={handleOnLoad}
      loading="eager"
    />
  }

  return <>
    {/* All Children will be styled according to img */}
    <img
      {...props}
      className={className}
      ref={ref}
      alt={alt}
      src={url}
      width={w}
      height={h}
      style={{
        ...style,
        marginTop,
        // ...(!isMobile
        //   ? {
        //       filter: blurValue ? `blur(${Math.abs(blurValue)}px)` : '',
        //       transition: 'filter .5s ease-in-out'
        //     }
        //   : {})
      }}
      onLoad={handleOnLoad}
      loading="eager"
    />
    {renderChildren()}
  </>
}
