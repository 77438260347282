import { useEffect, useRef } from 'react'

import get from 'lodash/get'
import every from 'lodash/every'


const checkContainTarget = (ref, e) => {
  const current = get(ref, 'current')

  if (!current) return false
  return current.contains(e.target)
}

export const useOutsideClick = (callback = () => {}) => {
  const ref = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (checkContainTarget(ref, event)) callback()
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return ref
}

export const useMultipleOutsideClick = (refs, callback = () => {}, deps) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if(every(refs, ref => !checkContainTarget(ref, event))) callback()
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
