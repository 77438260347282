import { useState, useEffect } from 'react'
import get from 'lodash/get'

export const useContainerDimensions = myRef => {
  const initWidth = get(myRef, 'current.offsetWidth', 0)
  const initHeight = get(myRef, 'current.offsetHeight', 0)

  const [dimensions, setDimensions] = useState({ width: initHeight || 0, height: initWidth || 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: get(myRef, 'current.offsetWidth', 0),
      height: get(myRef, 'current.offsetHeight', 0)
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  if (myRef.current) {
    const _width = get(myRef, 'current.offsetWidth', 0)
    const _height = get(myRef, 'current.offsetHeight', 0)

    const { width, height } = dimensions
    return {
      width: _width !== width ? _width : width,
      height: _height !== height ? _height : height
    }
  }

  const setHeight = (value) => {
    setDimensions({width: (dimensions && dimensions.width) || 0, height: value})
  }

  return {...dimensions, setHeight}
}
