import keys from 'lodash/keys'
import map from 'lodash/map'

import {
  FaNodeJs,
  FaJsSquare,
  FaReact,
  FaSass
} from 'react-icons/fa'

import {
  SiTestinglibrary,
  SiGraphql,
  SiGnubash,
  SiLinux,
  SiFigma,
  SiBootstrap,
  SiGit,
  SiDocker,
  SiKubernetes,
  SiPython,
  SiFlask,
  SiApache,
  SiApachekafka,
  SiPostgresql,
  SiMongodb
} from 'react-icons/si'

import imageData from './image-data'

const getUrl = (id, variant) => `https://imagedelivery.net/9bD4jGTLu2p50iCVRkMO1A/${ id }/${ variant }`

export const skillsLinks = [
  {
    name: 'Javascript | ES6',
    slug: 'js',
    Icon: FaJsSquare
  },
  {
    name: 'Node JS',
    slug: 'node',
    Icon: FaNodeJs
  },
  {
    name: 'React JS',
    slug: 'react',
    Icon: FaReact
  },
  {
    name: 'React Testing Library | Jest',
    slug: 'test',
    Icon: SiTestinglibrary
  },
  {
    name: 'MongoDB',
    slug: 'mongo',
    Icon: SiMongodb
  },
  {
    name: 'PostgreSql',
    slug: 'postgres',
    Icon: SiPostgresql
  },
  {
    name: 'Figma',
    slug: 'figma',
    Icon: SiFigma
  },
  {
    name: 'Bootstrap 5',
    slug: 'bg',
    Icon: SiBootstrap
  },
  {
    name: 'Sass | Scss',
    slug: 'sass',
    Icon: FaSass
  },
  {
    name: 'GraphQl',
    slug: 'graph',
    Icon: SiGraphql
  },
  {
    name: 'Python',
    slug: 'python',
    Icon: SiPython
  },
  {
    name: 'Flask',
    slug: 'flask',
    Icon: SiFlask
  },
  {
    name: 'Bash',
    slug: 'bash',
    Icon: SiGnubash
  },
  {
    name: 'Linux | MacOS',
    slug: 'linux',
    Icon: SiLinux
  },
  {
    name: 'Git | Github',
    slug: 'git',
    Icon: SiGit
  },
  {
    name: 'Docker',
    slug: 'docker',
    Icon: SiDocker
  },
  {
    name: 'Kubernetes',
    slug: 'k8s',
    Icon: SiKubernetes
  },
  {
    name: 'Apache Tika',
    slug: 'tika',
    Icon: SiApache
  },
  {
    name: 'Apache Kafka',
    slug: 'kafka',
    Icon: SiApachekafka
  },
]

const ranges = [
  [1, 300, 'clock'],
  [301, 500, 'mobile'],
  [501, 900, 'medium'],
  [901, 1024, 'public']
]
export const getImageVariant = (windowSize) => {

  for (let range of ranges) {
    if (windowSize >= range[ 0 ] && windowSize < range[ 1 ])
      return range[ 2 ]
  }

  return 'high'
}

const variantExeption = {
  'day-8-v2': 'high',
  'night-8-v2': 'high',
  'day-3-v3': 'high',
  'night-3-v3': 'high'
}

export const getImage = (name, windowSize) => {
  const { id, dimensions, type, local, path } = imageData[ `${name}` ]
  if (local) {
    return {
      url: process.env.PUBLIC_URL + path + name + '.' + type
    }
  }

  const variant = variantExeption[ name ] || getImageVariant(windowSize)
  // const variant = getImageVariant(windowSize)

  const url = getUrl(id, variant)
  return { url, ...dimensions}
}

export const getAllImagesList = (windowSize) => {
  const bgNames = keys(imageData)
  const bgLinks = map(bgNames, name => getImage(name, windowSize).url)

  return bgLinks
}
