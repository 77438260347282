import { createContext, useContext, useEffect } from 'react'
import { isMobile as mobileDetected } from 'react-device-detect'

import { useWindowDimensions } from '../hooks/useWindowDimensions'

import { config } from '../config'


const { MAX_MOBILE, MAX_TABLET, MAX_LAPTOPS } = config

export const DevicePropsContext = createContext()

export const DevicePropsProvider = ({ children }) => {
  const {height: windowHeight, width: windowWidth, initialProportion, currentProportion} = useWindowDimensions()
  const isMobile = mobileDetected && windowWidth <= MAX_MOBILE
  const isTablet = windowWidth > MAX_MOBILE && windowWidth <= MAX_TABLET
  const isLaptop = windowWidth > MAX_TABLET && windowWidth <= MAX_LAPTOPS
  const isLarge = !isMobile && !isTablet && !isLaptop

  const initialPageNumber =
    initialProportion <= 0.5 ? 1.15
    : initialProportion <= 1 ? 1.5
      : initialProportion <= 1.6 ? 2.2
      : 2.6
  const pageNumber = 
    currentProportion <= 0.5 ? 1.15
    : currentProportion <= 1 ? 1.5
      : currentProportion <= 1.6 ? 2.2
      : 2.6

  useEffect(() => {
    if ((isMobile || isTablet) && initialPageNumber !== pageNumber) {
      window.location.reload()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  return <DevicePropsContext.Provider value={{
      isMobile: isMobile || isTablet,
      isTablet,
      isLaptop,
      isLarge,
      windowHeight,
      windowWidth,
      mobileDetected,
      pageNumber: initialPageNumber,
      initialProportion
    }}>
      {children}
    </DevicePropsContext.Provider>
}

export const useDeviceProps = () => {
  const context = useContext(DevicePropsContext)
  if (!context) {
    throw new Error('useDeviceProps must be used within a DevicePropsProvider')
  }

  return context
}
