import { useEffect, useState } from 'react'
import isFunction from 'lodash/isFunction'

export const useTimeout = (time = 0, cb, ...args) => {
  const [ended, setEnded] = useState(!time)

  useEffect(() => {
    const timer = setTimeout(() => {
      setEnded(true)
      if (isFunction(cb))
        cb(...args)
    }, time * 1000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [])

  return [ ended ]
}
