import { ModeSwitch } from '../../ModeSwitch'
import { useModeProps } from '../../../Providers/ModeProps'
import { useSkillOpenedPermission } from '../../../Providers/SkillOpenedProps'

import './style.scss'


export const ModeChanger = ({ className , style}) => {
  const { switchMode } = useModeProps()
  const { opened } = useSkillOpenedPermission()

  return <ModeSwitch className={`${className} transition-all duration-1000`} style={{...style, opacity: opened ? '0.3' : '1'}} onClick={switchMode}/>
}
