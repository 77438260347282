import { 
  useCallback,
  // useEffect
} from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
// import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'

import { useDisablePinchZoomEffect } from './hooks/useDisablePinchZoomEffect'
import { useDisableDragImageEffect } from './hooks/useDisableDragImageEffect'
import { useDisableDocScrollEffect } from './hooks/useDisableDocScrollEffect'
import { useDeviceProps } from './Providers/DeviceProps'
import { useModeProps } from './Providers/ModeProps'
import { SkillOpenedProvider } from './Providers/SkillOpenedProps'
import { Preload } from './containers/Preload'
import { Structure } from './containers/Structure'
import { FullScreenPage } from './containers/Fullscreen'
// import { Controller } from './containers/Controllers'
import { Skills } from './containers/Skills'
import { Phrase } from './containers/Phrase'
import { ChestCurriculum } from './components/ChestCurriculum'
import { ModeChanger } from './components/compound/ModeChanger'

import { config } from './config'

import 'inobounce'
import './App.scss'


const { SPRING_CONFIG } = config
export const App = () => {
  useDisablePinchZoomEffect()
  useDisableDragImageEffect()
  useDisableDocScrollEffect()

  const { pageNumber } = useDeviceProps()
  const {
    scrollbarClasses,
    parallax,
    // onSetFocus
  } = useModeProps()

  const MainFrame = useCallback(() => {
    const phraseOffset = 1 - Math.pow(1 / pageNumber, 2)

    return <Parallax
        ref={parallax}
        pages={pageNumber}
        config={SPRING_CONFIG}
        className={`transition-all ${scrollbarClasses}`}
        style={{
          height: 'calc(var(--vh, 1vh) * 101)',
          bottom: '-2px',
          overflow: 'overlay',
          alignSelf: 'baseline',
          justifySelf: 'baseline'
        }}>
        <Structure />
        <ParallaxLayer factor={1} offset={phraseOffset} speed={1.2}>
          <div className="flex flex-col items-center md:mt-[-25vw] lg:mt-[-30vw] translate-y-[-50%] gap-y-14">
            <ChestCurriculum />
            <Phrase />
            <ModeChanger className="" />
          </div>
        </ParallaxLayer>
        {/* <ParallaxLayer
          factor={phraseOffset}
          offset={phraseOffset}
          style={{width: '0 !important'}}
          className="w-[0!important]">
          <RangeSlider
            className="focus-slider vertical-slider -translate-x-1/2 left-[50vw] w-[200px] opacity-30"
            defaultValue={[0, 0]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
            onInput={onSetFocus}
          />
        </ParallaxLayer> */}

      </Parallax>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Preload loadingEffect/>
      <FullScreenPage />
      <SkillOpenedProvider>
        <MainFrame />
        <div className="fixed top-0 left-0 z-[98] w-screen">
            <Skills />
        </div>
      </SkillOpenedProvider>
    </div>
  )
}
