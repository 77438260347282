import React, { useMemo } from 'react'
import { BodyStructure } from './BodyStructure'

import { useModeProps } from '../../Providers/ModeProps'
import { useDeviceProps } from '../../Providers/DeviceProps'


export const Structure = () => {
  const {
    opacity,
    // focus
  } = useModeProps()
  const { pageNumber } = useDeviceProps()

  const getMemoDayStructure = useMemo( () =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    <BodyStructure pages={pageNumber} />, [])
  const getMemoNightStructure =useMemo( () =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    <BodyStructure pages={pageNumber} mode="night" opacity={opacity} />, [opacity])

  return (
    <>
      { getMemoDayStructure }
      { getMemoNightStructure }
    </>
  )
}
